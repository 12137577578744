// var $doc = $(document);
//
// $doc.ready(function(e) {
//
//     var $subscriber_dataTable = $("#subscriber_dataTable");
//     var $form = $("form");
//     var $modal = $(".modal");
//
//     $subscriber_dataTable.DataTable({
//         "language": {
//             "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
//         },
//         "ajax": subscriberData,
//         "paging": true,
//         "serverSide": true,
//         "lengthChange": false,
//         "searching": true,
//         "columns": [
//             { "data": 'firstname', "name": 'firstname', "orderable": true },
//             { "data": 'lastname', "name": 'lastname', "orderable": true },
//             { "data": 'email', "name": 'email', "orderable": true },
//             { "data": 'created_at', "name": 'created_at', "orderable": true },
//             { "data": 'edit', "name": 'edit', "orderable": true , "render": function (data) {
//
//                     data = '<a class="btn btn-info btn-xs edit-button" href="' + data + '">Wijzigen</a>';
//
//                     return data
//
//                 } },
//             { "data": 'delete', "name": 'delete', "orderable": true , "render": function (data) {
//
//                     data = '<form action="' + data + '" class="delete" method="post"> {{ csrf_field() }} {{ method_field(\'DELETE\') }} <input type="submit" class="btn btn-info btn-xs delete-button" value="Verwijderen"> </form>';
//
//                     return data
//
//                 } }
//         ],
//         "info": true,
//         "autoWidth": false,
//         "pageLength": 20,
//         "initComplete": function(settings, json) {
//             $form.submit(function(e) {
//                 e.preventDefault();
//                 $modal.modal().one('click', '#delete', function() {
//                     $form.submit();
//                 });
//             });
//         }
//     });
// });