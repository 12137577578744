var $doc = $(document);

$doc.ready(function () {

    var $preview = $("#preview"), $message = $("#message");
    var $datetimepicker = $("#launch_at_picker");
    var $campaigns_dataTable = $("#campaigns_dataTable");
    var $campaignsSend_dataTable = $("#campaignsSend_dataTable");

    $datetimepicker.datetimepicker({
        format: 'DD-MM-YYYY HH:mm:ss'
    });
    $preview.html($message.val());
    $message.on('keydown', function (e) {
        $preview.html($message.val());
    });

    $campaigns_dataTable.DataTable({
        "language": {
            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
        },
        "ajax": campaignData,
        "columns": [
            { "data": 'subject',
                "render": function(data, type, row, meta){
                    if(type === 'display'){

                        seperated_data = data.split('_');

                        data= '<a href="' + seperated_data[0] + '">' + seperated_data[1] + '</a>';

                    }

                    return data
                },
                "name": 'subject' },
            { "data": 'launch_at', "name": 'launch_at' },
            { "data": 'total_subscribers', "name": 'total_subscribers' },
            { "data": 'tracking',
                "render": function(data, type, row, meta) {

                    if(data === 'Verzonden') {
                        data = '<p class="badge badge-pill badge-success">Verzonden</p>'
                    } else {
                        data = '<p class="badge badge-pill badge-primary">Niet verzonden</p>'
                    }

                    return data

                },
                "name": 'tracking' },
            { "data": 'deleted',
                "render": function (data, type, row, meta) {
                    if(data !== '-') {
                        data = '<p class="badge badge-pill badge-danger">Wordt verwijderd</p>'
                    }

                    return data
                },
                    "name": 'deleted' },
        ],
        "paging": true,
        "lengthChange": false,
        "searching": true,
        "ordering": false,
        "info": true,
        "autoWidth": false,
        "pageLength": 25,
        "processing": true,
        "serverSide": true
    });

    $('.leesMeerMinder').click(function () {
        var $target = $(this).data('target');
        if ($(this).html() == "Toon") {
            $('#' + $target).css('height', 'auto');
            $(this).html('Verberg');
        } else {
            $('#' + $target).css('height', '0px');
            $(this).html('Toon');
        }
    });

    var teller = 1;
    var image = $('#afbeelding');
    var form = new FormData();
    form.append('afbeelding', image);

    $('body').on('click', '[data-target="save"]', function(e) {
        // transferContent(e);
        transferHtmlContent($(e.target).attr('id'), "")
    });

    $('body').on('click', '[data-target="show_text"]', function (e) {
        text(e);
    });

    $('body').on('click', '[data-target="show_preview_old"]', function (e) {
        show_preview_old(e);
    });

    $('body').on('click', '[data-target="upload_html"]', function (e) {
        uploadHtml(e);
    })

});

function preview(teller) {
    // Declare the selectors for all the different elements
    var preview = $("#preview-" + teller), message = $("#message-" + teller), mobile = $("#mobile-" + teller),
        standard = $("#standard-" + teller);

    // Find the contents of the preview and replace them with the value of the message field
    preview.contents().find('body').html(message.val());

    // Every time a key is pressed down whilst the message field is being focussed on
    message.on('keydown', function (e) {
        preview.contents().find('body').html(message.val());
    });

    // Remove the class once the standard button is clicked
    standard.click(function (e) {
        preview.removeClass('desktop tablet mobile');
    });

    // Add the mobile class once the mobile button is clicked
    mobile.click(function (e) {
        preview.addClass('mobile');
    });
}

// Declare the tablecounter
var tablecounter = 1;

// Declare an array to hold the id of the tab that has already had all the eventlisteners set.
var limiter = [];

teller = 1;

preview(teller);

// Once a tab is clicked fire all functions inside this statement
$("#tabMenu").on("click", "a", function (e) {
    e.preventDefault();
    // If the element that was clicked contained the class add_tab, trigger the functions inside
    if (!$(this).hasClass('add_tab')) {
        $(this).tab('show');
        var teller = $(this).attr('data-id');
        preview(teller);
    }
    // If the element that was clicked was a span than ask if the user wants to remove the clicked variant tab
}).on("click", "span", function () {
        var $this = $(this);
        bootbox.confirm("Wilt u deze variant verwijderen?", function (result) {
            if (result) {
                var anchor = $(this).siblings('a');
                $(anchor.attr('href')).remove();
                $this.parent().remove();
                $(".nav-tabs li").children('a').first().click();
            }
        }.bind($this));
    });

function text(e) {

    // Get the button that was clicked
    var text = $(event.target);

    // Get the id of the clicked button
    var id = text.attr('id');

    // Remove the string so only the number remains
    id = id.replace('text-button-', '');

    // Get the contents of the text field
    var content = $("#text-" + id).val();
    // Prepare the content for the bootbox
    var newContent = '<textarea class="disabled" style="width: 65vh; height: 60vh;">' + content + '</textarea>';

    // Show a bootbox with the prepared content
    bootbox.alert({
        title: 'Tekstbericht',
        message: newContent,
        size: 'large'
    });

}

// Get the tab menu
var element = document.getElementById("tabMenu");

// If the tab menu exist
if (element) {
    // Count the children of the tab menu (the tabs within the menu are considered children)
    var elementcount = element.childElementCount;
    // Remove 1 from the elementcount to account for the "+" tab
    elementcount = elementcount - 1;
    // Assign the elementcout to the teller variable
    teller = elementcount;
}

// Once the add_tab button is clicked
$('#add_tab').click(function (e) {
    e.preventDefault();

    // Get the tabmenu
    var element = document.getElementById("tabMenu");

    // Count the children of the tabmenu
    var elementcount = element.childElementCount;

    // Subtract one to account for the add button itself
    elementcount = elementcount - 1;

    // Assign teller the value of the elementcount
    teller = elementcount;

    // Increment the variable
    teller++;

    // Replace all __i__ in the html template with the value of teller
    var html = html_template.replace(/__i__/g, teller);

    // Give id the value of teller
    var id = teller;

    // Create the tab_id for the new tab
    var tabId = 'tab' + id;

    // Create a new tab and put if before the add tab button
    $(this).closest('li').before('<li class="active"><a href="#tab' + id + '" data-id="' + teller + '">Nieuwe Variant</a> <span><i class="fa fa-minus red distance" aria-hidden="true"></i></span></li>');

    // Create the content of the tab and place it within the tab body
    $('.main-tab').append('<div class="tab-pane fade" id="' + tabId + '">' + html + ' </div>');
});

$('#add_tab').click(function (e) {
    // Get the selectors
    var preview = $("#preview-" + teller), message = $("#message-" + teller), text = $("#text-" + teller);

    // Replace the contents of the preview with the content of the html field
    preview.contents().find('body').html(message.val());

    // If a key is pressed within the html field then update the preview to reflect those changes
    message.on('keydown', function (e) {
        preview.contents().find('body').html(message.val());
    });

});

function validateForm(teller) {

    // Get the tab menu
    var element = document.getElementById("tabMenu");

    // Count the children of the tab menu
    var elementcount = element.childElementCount;

    // Subtract one for the add tab button
    elementcount = elementcount - 1;

    // Assign the value of elementcount to the teller variable
    teller = elementcount;

    // Get the value's of the fields
    var name = document.forms['campaign_form']['variant[' + teller + '][name]'].value;
    var subject = document.forms['campaign_form']['variant[' + teller + '][subject]'].value;
    var html = document.forms['campaign_form']['variant[' + teller + '][original_html]'].value;

    // Check if the fields are filled and show an error in the console if they are not
    if (name === '') {
        console.log('name ' + teller + ' not valid');
        // return false;
    }

    if (subject === '') {
        console.log('subject ' + teller + ' not valid');
        // return false;
    }

    if (html === '') {
        console.log('html ' + teller + ' not valid');
        // return false;
    }

}

// Get html of the buttons of the first variant
var buttons = $("#buttons-1").html();

var inner_content = $('#insertion_point-' + teller);

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

// Declare an empty variable
var imagepath = '';

function uploadHtml(event)
{

    var id = $(event.target).data('id');

    // Get the field that was clicked
    var $html = $("#file-" + id);

    // Get the HTML file
    var html = $html[0].files[0];

    // Create a formdata object
    var form = new FormData();

    // Insert the HTML into the form
    form.append('html', html);

    // Trigger the ajax function to upload the HTML
    $.ajax({
        url: '/uploadhtml',
        data: form,
        cacher: false,
        contentType: false,
        processData: false,
        dataType: 'json',
        type: 'POST',
        beforeSend: function (xhr) {
            var token = $('meta[name="csrf_token"]').attr('content');
            if (token) {
                return xhr.setRequestHeader('X-CSRF-TOKEN', token);
            }
        },
        success: function(data) {
            transferHtmlContent(id, data.content);
            $("#message-" + id).val(data.content);
        },
        error: function(data) {
            console.log("Oops! Er ging iets mis");
        }
    });
}

var result = getWeekNumber(new Date());

function remove_Footer(event) {

    // Get the remove button (<i>)
    var remove = $(event.target);

    // Get the parent of the <i> tag (which is the <span>)
    remove = remove.parent();

    // Get the id from the <span>
    var id = remove.attr('id');

    remove = remove.parent();
    remove = remove.parent();
    remove = remove.parent();
    remove = remove.parent();
    remove = remove.parent();
    remove = remove.parent();

    // Asks the user if they are sure they want to delete the footer
    bootbox.confirm("wilt u deze footer verwijderen?", function (result) {
        // If yes then remove the block
        if(result) {
            // $('#block-' + id).remove();
            remove.remove();
        }
    })

}

function transferHtmlContent(id, data) {

    var subtitle = $("#subtitle_field_" + id).val();

    var subtitleHtml = '<div class="subtitle_' + id + '" style="display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;">' + subtitle + '</div>';

    var html = $("#message-" + id);

    // Verwijder de subtitle div als deze bestaat
    html.val(html.val().replace(/<div class="subtitle_(.*?)*\/div>/g, ""));

    if(data.length < 1) {
        html.val(html.val().replace("</head>", "</head>" + subtitleHtml));
    } else {
        html.val(data);
        html.val(html.val().replace("</head>", "</head>" + subtitleHtml));
    }

    $("td, tr, div, span, table, a, i", $("#message-" + id)).removeAttr('id').removeAttr('data-target');
    $("span, i", $("#message-" + id)).remove();

    // Select the preview
    preview = $("#preview-" + id);

    // Insert the content inside the preview so the user doesn't have to press space in order to render the view
    preview.contents().find('body').html(html.val());

    // Show the preview
    $("#preview-block-" + id).removeClass('hidden');

}

function show_preview_old (event) {

    // Get the button
    var $show = $(event.target);

    // Get the id of the button
    var id = $show.attr('id');

    // Toggle the hidden class based on it's presence in the element
    if($("#preview-block-" + id).hasClass('hidden')) {
        $("#preview-block-" + id).removeClass('hidden');
    } else {
        $("#preview-block-" + id).addClass('hidden');
    }

}